import { Box, Flex, Spacing, Txt } from '@florencecard/components';
import { colors } from '@florencecard-shared/colors';
import { css } from '@emotion/react';
import Link from 'next/link';
import React from 'react';

// const openKGInicis = () => {
//   window.open(
//     'https://mark.inicis.com/mark/escrow_popup_v3.php?mid=MOIflore45',
//     'mark',
//     'scrollbars=no,resizable=no,width=565,height=683',
//   );
// };

export default function Footer() {
  return (
    <Box
      as="footer"
      padding="40px 20px"
      backgroundColor={colors.black}
      css={css`
        * {
          word-break: keep-all;
        }
      `}
    >
      <Flex.Center>
        <img
          src="https://assets.florencecard.me/images/logo-white.svg"
          alt="플로렌스카드"
          height={24}
        />
      </Flex.Center>
      <Spacing size={40} />
      {/*<Flex.Center>*/}
      {/*  <img*/}
      {/*    src="https://image.inicis.com/mkt/certmark/escrow/escrow_74x74_gray.png"*/}
      {/*    width={40}*/}
      {/*    height={40}*/}
      {/*    alt="클릭하시면 이니시스 결제시스템의 유효성을 확인하실 수 있습니다."*/}
      {/*    css={{ cursor: 'pointer' }}*/}
      {/*    onClick={openKGInicis}*/}
      {/*  />*/}
      {/*</Flex.Center>*/}
      {/*<Spacing size={16} />*/}
      <Txt fontSize={13} lineHeight="19px" color={colors.grayLight} textAlign="center" as="div">
        상호 : 플로렌스 | 대표자 : 조현민 | 개인정보책임자 : 이은미
      </Txt>
      <Spacing size={8} />
      <Txt fontSize={13} lineHeight="19px" color={colors.grayLight} textAlign="center" as="div">
        사업자등록번호 :{' '}
        <strong style={{ fontWeight: 400, color: colors.white }}>168-01-02960</strong>
      </Txt>
      <Spacing size={8} />
      <Txt
        fontSize={13}
        lineHeight="19px"
        color={colors.white}
        textAlign="center"
        as="div"
        fontWeight="regular"
      >
        통신판매업신고번호 : 제 2022-성남분당A-0636
      </Txt>
      <Spacing size={8} />
      <Txt fontSize={13} lineHeight="19px" color={colors.grayLight} textAlign="center" as="div">
        주소 : 경기도 성남시 분당구 판교역로 192번길 16, 806호
      </Txt>
      <Spacing size={8} />
      <Txt fontSize={13} lineHeight="19px" color={colors.grayLight} textAlign="center" as="div">
        문의 :{' '}
        <a href="mailto://florencecard2@gmail.com" style={{ fontWeight: 400, color: '#ffffff' }}>
          florencecard2@gmail.com
        </a>{' '}
        또는{' '}
        <a href="tel://070-8095-5850" style={{ fontWeight: 400, color: '#ffffff' }}>
          070-8095-5850
        </a>
      </Txt>
      <Spacing size={24} />
      <Flex.Center>
        <Link href="/policy/terms-of-use">
          <Txt
            as="a"
            fontSize={13}
            lineHeight="19px"
            color={colors.white}
            cursor="pointer"
            paddingLeft={8}
            paddingRight={8}
          >
            이용약관
          </Txt>
        </Link>
        <Box width={1} height={17} backgroundColor={colors.grayLight} />
        <Link href="/policy/privacy-statement">
          <Txt
            as="a"
            fontSize={13}
            lineHeight="19px"
            color={colors.white}
            cursor="pointer"
            paddingLeft={8}
            paddingRight={8}
          >
            개인정보처리방침
          </Txt>
        </Link>
      </Flex.Center>
      <Spacing size={56} />
      <Txt fontSize={13} lineHeight="19px" color={colors.grayLight} textAlign="center" as="div">
        COPYRIGHT© FLORENCE ALL RIGHT RESERVED
      </Txt>
    </Box>
  );
}
